import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  ChangeDetectionStrategy,
  input,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormDialogCloseReason } from '@desquare/enums';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'app-form-dialog',
  imports: [TranslateModule],
  template: `
    <div class="modal-container">
      <div class="modal-header">
        <h3>{{ headerText() | translate }}</h3>
        <button
          type="button"
          class="btn-close btn-close-white"
          data-dismiss="modal"
          (click)="modal.dismiss()"
        ></button>
      </div>
      <div class="modal-body">
        <ng-container #bodyWrapper>
          <ng-content></ng-content>
        </ng-container>
      </div>
      @if (showFooter()) {
        <div class="modal-footer">
          @if (showBackButton()) {
            <button type="button" class="btn btn-primary" (click)="back()">
              {{ backButtonText() | translate }}
            </button>
          }

          <div class="push-right">
            <button
              type="button"
              class="btn btn-outline-warning me-2"
              [disabled]="dismissButtonEnabled()"
              (click)="dismiss()"
            >
              {{ dismissButtonText() | translate }}
            </button>
            @if (showDeleteButton()) {
              <button
                type="button"
                class="btn btn-outline-danger me-2"
                (click)="delete()"
              >
                {{ deleteButtonText() | translate }}
              </button>
            }
            @if (showExtraButton()) {
              <button
                type="button"
                class="btn btn-success me-2"
                (click)="extra()"
              >
                {{ extraButtonText() | translate }}
              </button>
            }

            <button
              [disabled]="!valid"
              type="button"
              [class.btn-outline-danger]="useDangerButton() && pristine"
              [class.btn-outline-success]="!useDangerButton() && pristine"
              [class.btn-danger]="useDangerButton() && !pristine"
              [class.btn-success]="!useDangerButton() && !pristine"
              class="btn"
              (click)="accept()"
            >
              {{ closeButtonText() | translate }}
            </button>
          </div>
        </div>
      }
    </div>
  `,
  styles: [
    `
      .push-right {
        margin-left: auto;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDialogComponent {
  @ViewChild('bodyWrapper', {
    static: true,
  })
  bodyWrapper?: ElementRef;

  showFooter = input(true);
  useDangerButton = input(false);
  dismissButtonText = input('NO');
  dismissButtonEnabled = input(false);
  deleteButtonText = input('DELETE');
  showDeleteButton = input(false);
  closeButtonText = input('YES');
  headerText = input('FORM_DIALOG_HEADER');
  pristine = input(true);
  valid = input(false);
  backButtonText = input('BACK');
  extraButtonText = input('EXT');
  showBackButton = input(false);
  showExtraButton = input(false);

  // onSubmit can be any object from a form
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() values: any = {};

  constructor(public modal: NgbActiveModal) {}

  accept() {
    this.modal.close(this.values);
  }

  dismiss() {
    this.modal.dismiss(this.values);
  }

  delete() {
    this.modal.close(FormDialogCloseReason.DELETE);
  }

  back() {
    this.modal.close(FormDialogCloseReason.BACK);
  }

  extra() {
    this.modal.close(FormDialogCloseReason.EXTRA);
  }
}
